@import "~react-datepicker/dist/react-datepicker.min.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "react-date-range/dist/styles.css";
// main style file
@import "react-date-range/dist/theme/default.css";
// theme css file


@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Muli Bold";
  src: url("../../public/fonts/Muli-Bold.eot");
  src: url("../../public/fonts/Muli-Bold.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Muli-Bold.woff2") format("woff2"),
  url("../../public/fonts/Muli-Bold.woff") format("woff"),
  url("../../public/fonts/Muli-Bold.ttf") format("truetype"),
  url("../../public/fonts/Muli-Bold.svg#Muli-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Regular";
  src: url("../../public/fonts/Muli-Regular.eot");
  src: url("../../public/fonts/Muli-Regular.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Muli-Regular.woff2") format("woff2"),
  url("../../public/fonts/Muli-Regular.woff") format("woff"),
  url("../../public/fonts/Muli-Regular.ttf") format("truetype"),
  url("../../public/fonts/Muli-Regular.svg#Muli-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Light";
  src: url("../../public/fonts/Muli-Light.eot");
  src: url("../../public/fonts/Muli-Light.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Muli-Light.woff2") format("woff2"),
  url("../../public/fonts/Muli-Light.woff") format("woff"),
  url("../../public/fonts/Muli-Light.ttf") format("truetype"),
  url("../../public/fonts/Muli-Light.svg#Muli-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Didot";
  src: url("../../public/fonts/UVFDidotLTStd-Italic.eot");
  src: url("../../public/fonts/UVFDidotLTStd-Italic.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/UVFDidotLTStd-Italic.woff2") format("woff2"),
  url("../../public/fonts/UVFDidotLTStd-Italic.woff") format("woff"),
  url("../../public/fonts/UVFDidotLTStd-Italic.ttf") format("truetype"),
  url("../../public/fonts/UVFDidotLTStd-Italic.svg#UVFDidotLTStd-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Mussica";
  src: url("../../public/fonts/Mussica.eot");
  src: url("../../public/fonts/Mussica.eot?#iefix") format("embedded-opentype"),
  url("../../public/fonts/Mussica.woff2") format("woff2"),
  url("../../public/fonts/Mussica.woff") format("woff"),
  url("../../public/fonts/Mussica.ttf") format("truetype"),
  url("../../public/fonts/Mussica.svg#Mussica") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary-bold: "Muli Bold"; // Weight 700
$primary-regular: "Muli Regular"; // Weight 400
$primary-light: "Muli Light"; // Weight 300

body {
  margin: 0;
  font-family: $primary-regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: 14px;
}

input {
  background-color: transparent;
}

button {
  &:focus {
    outline: none;
  }
}

//Customization

.rdrWeekDay {
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.rdrMonthAndYearWrapper {
  justify-content: center;
  padding-top: 0;
}

.rdrMonthAndYearPickers {
  font-size: 14px;
  flex: initial;
}

.rdrNextButton i {
  border-width: 5px 5px 5px 6px;
}

.rdrPprevButton i {
  border-width: 5px 6px 5px 5px;
}

.rdrMonth {
  position: relative;
  padding: 0;
  width: 350px;

  &:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    position: absolute;
    z-index: 1;
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDay {
  height: 58px;

  &.rdrDayPassive .rdrDayNumber span {
    color: #d5dce0 !important;
  }
}

.rdrInRange {
  background-color: rgba(65, 147, 119, 0.3);
  border-top: 1px solid rgba(65, 147, 119, 1);
  border-bottom: 1px solid rgba(65, 147, 119, 1);
}

.rdrStartEdge,
.rdrEndEdge {
  background-color: rgba(65, 147, 119, 0.3);
  border-top: 1px solid rgba(65, 147, 119, 1);
  border-bottom: 1px solid rgba(65, 147, 119, 1);

  ~ .rdrDayNumber span {
    color: white !important;
    font-weight: bold;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(65, 147, 119, 1);
    border-radius: 100%;
  }
}

.rdrDayNumber {
  font-size: 14px;

  span {
    line-height: initial;
    color: black !important;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  content: none;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge,
.rdrStartEdge {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrEndEdge {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.rdrDayEndOfWeek .rdrInRange {
  border-right: 1px solid rgba(65, 147, 119, 1);
}

.rdrDayStartOfWeek .rdrInRange {
  border-left: 1px solid rgba(65, 147, 119, 1);
}

// Datepicker
.react-datepicker {
  &__input-container {
    input {
      width: 100%;
      height: 48px;
      padding-left: 24px;
      outline: none;
      border-radius: 8px;
    }
  }

  &__triangle {
    &::before,
    &::after {
      right: 16px;
      left: auto !important;
    }
  }

  &__day-name,
  &__day {
    width: 2.5rem;
    line-height: 2.5rem;
  }

  * {
    font-family: $primary-regular;
  }
}

.font-bold,
b,
strong {
  font-family: "Muli Bold", sans-serif;
}

.font-normal {
  font-family: "Muli Regular", sans-serif;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #ff3d00;
}

// Style for select multiple positions
.select-position__control {
  .select-position__value-container {
    padding: 12px 0;
  }

  .select-position__clear-indicator {
    display: none;
  }

  .select-position__multi-value {
    background-color: rgba(65, 147, 119, 1);
    border-radius: 4px;
    padding: 4px 8px;
    align-items: center;

    * {
      color: #ffffff;
    }
  }

  .select-position__multi-value__remove {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #ffcc00;
    }
  }
}

// Banner slick slider
.banner-slick-wrapper {
  .slick-dots {
    bottom: 12px;

    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: rgba(65, 147, 119, 1);

      button:before {
        content: none;
      }

      &.slick-active {
        background-color: #ffffff;
      }
    }
  }
}

.react-tiny-popover-container {
  z-index: 99;
}

.sb-avatar__image {
  object-fit: cover;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}

.lds-spinner div {
  transform-origin: 18px 18px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1px;
  left: 18px;
  width: 2px;
  height: 8px;
  border-radius: 10%;
  background: rgba(253, 194, 109, 1);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
